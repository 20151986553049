.websiteBody{
    margin: 0;
}
.Page{
     margin:auto;
     width:100%;
     pointer-events:auto;
 }
 .Page-new{
    margin:auto;
    /* width:100%; */
    pointer-events:auto;
    size: A5;
 }
.Page-outer{
    pointer-events:none;
    z-index:1;
}
.Page-outer-new{
    pointer-events:none;
    z-index:1;
}
.Frame{
    position:relative;
    pointer-events:none;
    width:100%;
    margin-left:0;
    margin:auto;
    background-color:rgba(255, 255, 255, 1);
}
.Frame-new{
    position:relative;
    pointer-events:none;
    width:100%;
    margin-left:0;
    margin:auto;
    background-color:rgba(255, 255, 255, 1);
}
.Frame-outer{
    pointer-events:none;
}
.Frame-outer-new{
    pointer-events:none;
}
.image-10{
    position:relative;
    z-index:27;
    pointer-events:none;
    margin-left:472px;
    width:123px;
    min-width:123px;
    height:32px;
    margin-top:0px;
    margin-bottom:388px;
    min-height:32px;

}
.image-10-outer{
    pointer-events:none;
    z-index:27;
    position:relative;
    display:flex;
    justify-content: center;

}

.q10-34-am-Sun-14-Jul{
    position:relative;
    z-index:7;
    pointer-events:none;
    margin-left:18px;
    /*width:0px;*/
    min-width:94px;
    margin-top:-420px;
    margin-bottom:406px;
    min-height:14px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-weight:300;
    font-size:8px;
    line-height:14px;
}
.q10-34-am-Sun-14-Jul-outer{
    pointer-events:none;
    position:relative;
    display:flex;
}
.Txn-no--123011911283{
    position:relative;
    z-index:6;
    pointer-events:none;
    margin-left:130px;
    width:301px;
    min-width:100px;
    margin-top:-420px;
    margin-bottom:406px;
    min-height:14px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;

    font-weight:500;
    font-size:7px;
    line-height:14px;
}
.Txn-no--123011911283-outer{
    pointer-events:none;
    position:relative;
    display:flex;
    /* justify-content: left; */
}
.Txn-no-heading{

    font-family: Geeza Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;

    color: #003A63;
}
.section-heading{

    font-family: Geeza Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    /* identical to box height */


    color: #000000;
}
.Ref--1022738{
    position:relative;
    z-index:5;
    pointer-events:none;
    margin-left:247px;
    width:47px;
    min-width:46px;
    margin-top:-420px;
    margin-bottom:406px;
    min-height:14px;
    text-align:left;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;

    font-weight:300;
    font-size:6px;
    line-height:14px;
}
.Ref--1022738-outer{
    pointer-events:none;
    z-index:5;
    position:relative;
    display:flex;
    padding-left: 95px;
}
.LuLu-International-E{
    position:relative;
    z-index:11;
    pointer-events:none;
    margin-left:343px;
    width:157px;
    min-width:127px;
    margin-top:-407px;
    margin-bottom:356px;
    min-height:51px;
    text-align:left;
    color:rgba(0, 0, 0, 1);
    font-style:normal;

    font-weight:400;
    font-size:6px;
    line-height:8.5px;
}
.LuLu-International-E-outer{
    pointer-events:none;
    position:relative;
    display:flex;
    margin-top: 4px;
    justify-content: center;

}
.LuLu-International-address{
    pointer-events:none;
    position:relative;
    display:flex;
    margin-top: 4px;
    justify-content: center;

}
.LuLu-International-E-1{
    color:rgba(0, 0, 0, 1);
    font-style:normal;

    font-weight:600;
    font-size:6px;
    line-height:8.5px;
}
.LuLu-International-E-2{
    font-family: Geeza Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 34px;
    /* or 170% */

    text-align: center;

    color: #000000;
}
.VAT-Reg--10293829180{
    position:relative;
    z-index:9;
    pointer-events:none;
    margin-left:18px;
    width:123px;
    min-width:123px;
    margin-top:-399px;
    margin-bottom:365px;
    min-height:34px;
    text-align:right;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:8.5px;
}
.VAT-Reg--10293829180-outer{
    pointer-events:none;
    z-index:9;
    position:relative;
    display:flex;
    /* float: right; */
    margin-left: 400px;
    /* justify-content: right; */
    text-align: right;
}
.VAT-Reg--10293829180-1{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:6px;
    line-height:8.5px;
}
.VAT-Reg--10293829180-2{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:8px;
    margin-top:-.20px;
    line-height:8.5px;
}
.VAT-Reg--10293829180-3{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:8px;
    margin-top:-10px;
    line-height:8.5px;
}
.Tax-Invoice{
    position:relative;
    z-index:8;
    pointer-events:none;
    margin-left:220px;
    width:47px;
    min-width:47px;
    margin-top:-375px;
    margin-bottom:361px;
    min-height:14px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:700;
    font-size:7px;
    line-height:14px;
}
.Tax-Invoice-outer{
    pointer-events:none;
    position:relative;
    display:flex;
    justify-content: center;
    padding-top : 10px;
}
.Tax-Invoice-outer-arabic{
    pointer-events:none;
    position:relative;
    display:flex;
    justify-content: center;
}
.Tax-Invoice-heading{
    font-family: Geeza Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #003A63;
}
.Payment-Details{
    position:relative;
    z-index:26;
    pointer-events:none;
    margin-left:403px;
    width:109px;
    min-width:109px;
    margin-top:-327px;
    margin-bottom:316px;
    min-height:11px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:9px;
    line-height:14px;
}
.Payment-Details-outer{
    pointer-events:none;
    z-index:26;
    position:relative;
    display:flex;
}
.Customer-Details{
    position:relative;
    z-index:16;
    pointer-events:none;
    margin-left:18px;
    width:111px;
    min-width:111px;
    margin-top:-327px;
    margin-bottom:310px;
    min-height:17px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:9px;
    line-height:14px;
}
.Customer-Details-outer{
    pointer-events:none;
    z-index:16;
    position:relative;
    display:flex;
}
.Rectangle-3468260{
    position:relative;
    z-index:3;
    pointer-events:none;
    margin-left:398px;
    width:182px;
    min-width:182px;
    height:13px;
    margin-top:-326px;
    margin-bottom:314px;
    min-height:13px;
    background-color:rgba(240, 240, 240, 1);
}
.Rectangle-3468260-outer{
    pointer-events:none;
    z-index:3;
    position:relative;
    display:flex;
}
.Line-131{
    position:relative;
    z-index:28;
    pointer-events:none;
    /*transform:rotate(2.4848083448933725e-17deg);*/
    margin-left:18px;
    width:367px;
    min-width:367px;
    margin-top:-314px;
    margin-bottom:314px;
    /*min-height:undefinedpx;*/
    border:0.5px solid rgba(0, 0, 0, 1);
}
.divider{
    border:0.5px solid rgba(0, 0, 0, 1);
}

.Line-131-outer{
    pointer-events:none;
    z-index:60;
    position:relative;
    display:flex;
}
.No-1009283192-Name-Z{
    position:relative;
    z-index:17;
    pointer-events:none;
    margin-left:9px;
    width:374px;
    min-width:374px;
    margin-top:-311px;
    margin-bottom:195px;
    min-height:116px;
    text-align:left;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    /*line-height:3px;*/
}
.No-1009283192-Name-Z-outer{
    pointer-events:none;
    z-index:17;
    position:relative;
    display:flex;
}
.heading-content-1{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-weight:400;
    font-size:7px;
    /*line-height:3px;*/
}
.heading-content-1-value{
    color:rgba(0, 0, 0, 1);
    padding-right: 8px;
    font-style:normal;
    font-weight:400;
    font-size:7px;
    text-transform: uppercase;
    /*line-height:3px;*/
}
.No-1009283192-Name-Z-2{
   color:rgba(0, 0, 0, 1);
   font-style:normal;
   font-family: 'Inter', sans-serif;
   font-weight:300;
   font-size:8px;
   line-height:10px;
}
.Rectangle-3468261{
    position:relative;
    z-index:4;
    pointer-events:none;
    margin-left:398px;
    width:182px;
    min-width:182px;
    height:169px;
    margin-top:-311px;
    margin-bottom:142px;
    min-height:169px;
    background-color:rgba(240, 240, 240, 1);
}
.Rectangle-3468261-outer{
    pointer-events:none;
    z-index:4;
    position:relative;
    display:flex;
}
.Payment-amount-15-00{
    position:relative;
    z-index:20;
    pointer-events:none;
    margin-left:405px;
    width:161px;
    min-width:161px;
    margin-top:-301px;
    margin-bottom:157px;
    min-height:144px;
    text-align:left;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    /*font-size:nullpx;*/
    line-height:8px;
}
.Payment-amount-15-00-outer{
    pointer-events:none;
    z-index:20;
    position:relative;
    display:flex;
}
.Payment-amount-15-00-1{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-2{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-3{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-4{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-5{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-6{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-7{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-8{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-9{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-10{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-11{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-12{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-13{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-14{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Payment-amount-15-00-15{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:10px;
    line-height:10px;
}
.Payment-amount-15-00-16{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:8px;
    line-height:8px;
}
.Payment-amount-15-00-17{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:10px;
    line-height:10px;
}
.Payment-amount-15-00-18{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Cash---Card---CQ-Ban{
    position:relative;
    z-index:21;
    pointer-events:none;
    margin-left:407px;
    width:97px;
    min-width:97px;
    margin-top:-187px;
    margin-bottom:150px;
    min-height:37px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:8px;
    line-height:10px;
}
.Cash---Card---CQ-Ban-outer{
    pointer-events:none;
    z-index:21;
    position:relative;
    display:flex;
}
.Beneficiary-Details{
    position:relative;
    z-index:18;
    pointer-events:none;
    margin-left:18px;
    width:111px;
    min-width:111px;
    margin-top:-183px;
    margin-bottom:166px;
    min-height:17px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:9px;
    line-height:14px;
}
.Beneficiary-Details-outer{
    pointer-events:none;
    z-index:18;
    position:relative;
    display:flex;
}
.Line-132{
    position:relative;
    z-index:29;
    pointer-events:none;
    /*transform:rotate(2.4848083448933725e-17deg);*/
    margin-left:18px;
    width:367px;
    min-width:367px;
    margin-top:-170px;
    margin-bottom:170px;
    /*min-height:undefinedpx;*/
    border:0.5px solid rgba(0, 0, 0, 1);
}
.Line-132-outer{
    pointer-events:none;
    z-index:29;
    position:relative;
    display:flex;
}
.Name-Zumail-K-Relati{
    position:relative;
    z-index:19;
    pointer-events:none;
    margin-left:19px;
    width:235px;
    min-width:235px;
    margin-top:-168px;
    margin-bottom:52px;
    min-height:116px;
    text-align:left;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    /*font-family:;*/
    font-weight:400;
    font-size:8px;
    line-height:10px;
}
.Name-Zumail-K-Relati-outer{
    pointer-events:none;
    z-index:19;
    position:relative;
    display:flex;
}
.heading-content-2{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:400;
    font-size:7px;
    line-height:10px;
}
/*.Name-Zumail-K-Relati-2{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-3{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-4{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-5{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-6{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-7{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-8{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-9{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-10{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-11{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-12{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-13{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-14{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-15{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-16{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-17{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
/*.Name-Zumail-K-Relati-18{*/
/*    color:rgba(0, 0, 0, 1);*/
/*    font-style:normal;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-weight:400;*/
/*    font-size:8px;*/
/*    line-height:10px;*/
/*}*/
.I-hereby-confirm-tha{
    /* position:relative;
    z-index:10;
    pointer-events:none; */
    /* margin-left:400px; */
    /* width:190px;
    min-width:190px; */
    margin-top:-130px;
    margin-bottom:74px;
    /* min-height:56px; */
    /* text-align:left; */
    /* display:flex; */
    /* flex-direction:column; */
    /* justify-content:flex-start; */
    color:rgba(0, 0, 0, 1);
    font-style:italic;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:8px;
    line-height:14px;
    justify-content: center;
}
.declare{

    color:rgba(0, 0, 0, 1);
    font-style:italic;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:8px;
    line-height:14px;
    justify-content: center;
    line-break: auto;
}
.I-hereby-confirm-tha-outer{
    pointer-events:none;
    position:relative;
    display:flex;
    justify-content: center;
}
.Agent-s-Signature---{
    position:relative;
    z-index:15;
    pointer-events:none;
    margin-left:444px;
    width:101px;
    min-width:99px;
    margin-top:-49px;
    margin-bottom:40px;
    min-height:9px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:7px;
    line-height:7px;
}
.Agent-s-Signature----outer{
    pointer-events:none;
    z-index:15;
    position:relative;
    display:flex;
}
.Cashier{
    position:relative;
    z-index:14;
    pointer-events:none;
    margin-left:344px;
    width:32px;
    min-width:32px;
    margin-top:-49px;
    margin-bottom:40px;
    min-height:9px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:7px;
    line-height:7px;
}
.Cashier-outer{
    pointer-events:none;
    z-index:14;
    position:relative;
    display:flex;
}
.Created-By{
    position:relative;
    z-index:13;
    pointer-events:none;
    margin-left:209px;
    width:46px;
    min-width:45px;
    margin-top:-49px;
    margin-bottom:40px;
    min-height:9px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:7px;
    line-height:7px;
}
.Created-By-outer{
    pointer-events:none;
    z-index:13;
    position:relative;
    display:flex;
}
.Customer-s-Signature{
    position:relative;
    z-index:13;
    pointer-events:none;
    margin-left:46px;
    width:89px;
    min-width:89px;
    margin-top:-49px;
    margin-bottom:40px;
    min-height:9px;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:7px;
    line-height:7px;
}
.Customer-s-Signature-outer{
    pointer-events:none;
    z-index:12;
    position:relative;
    display:flex;
}
.Line-136{
    position:relative;
    z-index:33;
    pointer-events:none;
    /*transform:rotate(-4.969616689786745e-17deg);*/
    margin-left:434px;
        width:100px;
    min-width:100px;
    margin-top:-19px;
    margin-bottom:17px;
    /*min-height:undefinedpx;*/
    border:1px solid rgba(0, 0, 0, 1);
}
.Line-136-outer{
    pointer-events:none;
    /*z-index:33;*/
    position:relative;
    display:flex;
}
.Line-135{
    position:relative;
    z-index:32;
    pointer-events:none;
    /*transform:rotate(-4.969616689786745e-17deg);*/
    margin-left:304px;
    width:113px;
    min-width:113px;
    margin-top:-20px;
    margin-bottom:18px;
    /*min-height:undefinedpx;*/
    border:1px solid rgba(0, 0, 0, 1);
}
.Line-135-outer{
    pointer-events:none;
    z-index:32;
    position:relative;
    display:flex;
}
.Line-134{
    position:relative;
    z-index:31;
    pointer-events:none;
    /*transform:rotate(-4.969616689786745e-17deg);*/
    margin-left:173px;
    width:113px;
    min-width:113px;
    margin-top:-21px;
    margin-bottom:19px;
    /*min-height:undefinedpx;*/
    border:0.5px solid rgba(0, 0, 0, 1);
}
.Line-134-outer{
    pointer-events:none;
    z-index:31;
    position:relative;
    display:flex;
}
.Line-133{
    position:relative;
    z-index:30;
    pointer-events:none;
    /*transform:rotate(-4.969616689786745e-17deg);*/
    margin-left:39px;
    width:113px;
    min-width:113px;
    margin-top:-21px;
    margin-bottom:19px;
    /*min-height:undefinedpx;*/
    border:0.5px solid rgba(0, 0, 0, 1);
}
.Line-133-outer{
    pointer-events:none;
    z-index:30;
    position:relative;
    display:flex;
}
.q50-00{
    position:relative;
    z-index:25;
    pointer-events:none;
    margin-left:116.47058823529412%;
    width:6.38655462184874%;
    height:4.761904761904762%;
    margin-top:49.57983193277311%;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:8px;
    letter-spacing:0.4px;
    line-height:13px;
}
.q50-00-outer{
    pointer-events:none;
    z-index:25;
    position:absolute;
    display:flex;
    width:100%;
    height:100%;
    left:0;
    top:0;
}
.q50-00-1{
    position:relative;
    z-index:24;
    pointer-events:none;
    margin-left:104.2016806722689%;
    width:6.38655462184874%;
    height:4.761904761904762%;
    margin-top:49.57983193277311%;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:300;
    font-size:8px;
    letter-spacing:0.4px;
    line-height:13px;
}
.q50-00-1-outer{
    pointer-events:none;
    z-index:24;
    position:absolute;
    display:flex;
    width:100%;
    height:100%;
    left:0;
    top:0;
}
.Balance{
    position:relative;
    z-index:23;
    pointer-events:none;
    margin-left:115.7983193277311%;
    width:7.0588235294117645%;
    height:4.0476190476190474%;
    margin-top:42.6890756302521%;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-weight:300;
    font-size:8px;
    line-height:13px;
}
.Balance-outer{
    pointer-events:none;
    z-index:23;
    position:absolute;
    display:flex;
    width:100%;
    height:100%;
    left:0;
    top:0;
}
.Amount-Out{
    position:relative;
    z-index:22;
    pointer-events:none;
    margin-left:100.16806722689076%;
    width:10.420168067226891%;
    height:4.0476190476190474%;
    margin-top:42.6890756302521%;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-weight:300;
    font-size:8px;
    line-height:13px;
}
.Amount-Out-outer{
    pointer-events:none;
    z-index:22;
    position:absolute;
    display:flex;
    width:100%;
    height:100%;
    left:0;
    top:0;
}
.payment-details{
    color:rgba(0, 0, 0, 1);
    font-style:normal;
    font-family: 'Inter', sans-serif;
    font-weight:600;
    font-size:10px;
    line-height:8.5px;
}
.customer-details{
    color:rgba(0, 0, 0, 1);
    font-style:normal;

    font-weight:600;
    font-size:10px;
    line-height:8.5px;
}
.Lulu-exchange-heading{
    font-family: Geeza Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 25px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #003A63;
}
.q10-34-am-Sun-14-Jul-date{

    font-family: Geeza Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;

    color: #000000;
}
.bill-payment-receipt{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 30px;
    text-align: center;
    color: #003A63;
}
.bill-payment-receipt-outer{
    pointer-events:none;
    position:relative;
    display:flex;
    justify-content: center;
    padding-bottom: 4px;
    padding-top : 2px;
}
.square{
    height: 270px;
    width: 240px;
    border: 1px solid #01AFF0;
    border-radius: 12px;
    margin-left:8px;

}
.declaration{
    font-family: Geeza Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    /* or 78% */

    /* text-transform: capitalize; */

    color: #000000;
}
.declaration-outer{
    pointer-events:none;
    position:relative;
    display:flex;
    margin-left: 7px;
    margin-top: 2px;
}
.vat-invoice{
    font-family: Geeza Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    /* identical to box height */
    color: #000000;
    justify-content: right;

}
.new-divider{
    border: 1px solid #01AFF0;
    transform: rotate(0.08deg);
}
.agent-info{
    font-family: Geeza Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #000000;
}
.label{
    color:rgba(0, 0, 0, 1);
    font-style:normal;

    font-weight:400;
    font-size:7px;
}
